import React, {useState, useEffect} from 'react'
import { useRouter } from 'next/router';

//react-icons
import { CiCalendar } from "react-icons/ci";
import { GrLocation } from "react-icons/gr";

import { RiHeartLine, RiHeartFill } from "react-icons/ri";

import { CoverPhotoLesson } from '@/src/atoms/coverPhotoLesson';
import { PublicLessonCardType } from '@/src/types/public/lesson';
import { displayDateShort2 } from '@/src/helpers/datetime_locale'

import { ProfilePictureIcon } from '@/src/atoms-new/ProfilePictureIcon';

//redux
import { useAppSelector } from '@/src/hooks/useSelector';

//display helpers
import { displayGenre } from '@/src/helpers/displayDropdownChoiceValue';
import { displayTargetAudience } from '@/src/helpers/displayDropdownChoiceValue';


interface PublicLessonCardProps {
  onClick: () => void;
  lessonData: PublicLessonCardType;
  isFollowing?: boolean;
  bookmarkHandler: () => void;
  l?: 'en'|'jp';
}

export const PublicLessonCard = ({
  onClick,
  lessonData,
  isFollowing=false,
  bookmarkHandler,
  l='jp'
}:PublicLessonCardProps) => {

  const onBookmarkClick = (e:React.SyntheticEvent) => {
    e.stopPropagation();
    bookmarkHandler()
  }
  const userData = useAppSelector(state => state.user).data
  const {user} = userData

  const {coverPhoto, title, description, genre, targetAudience, instructor, lessonClass} = lessonData
  const {start_datetime, locationName } =lessonClass

  return (
    <div 
      onClick={onClick}
      className={`
        flex flex-row 
        gap-2 w-full bg-white rounded-md 
        border relative cursor-pointer
        hover:shadow-md hover:bg-gray-50
      `}
    >
      {/** COVER PICTURE */}
      <div className='hidden sm:block w-[120px] md:w-[240px] lg:w-[300px]'>
        <CoverPhotoLesson
          className="w-full aspect-video rounded-l-md"
          textSize=""
          image={coverPhoto}
        />
      </div>

      {/** RIGHT SIDE */}
      <div className="flex flex-col justify-start items-start p-2 px-4 sm:px-2  gap-1">
        <div className="flex flex-col-reverse sm:flex-col gap-1">
          <div className="flex flex-col sm:flex-row gap-1 sm:gap-6 ">
            <div className='flex flex-row gap-1 items-center'>
              <CiCalendar size={20} className='text-[#F2503A]'/>
              <p className="text-[14px] text-gray-600 text-nowrap">
                  {start_datetime
                  ? displayDateShort2(new Date(start_datetime), 'en')
                  : "Date not available"}
              </p>
            </div>

            <div className='flex flex-row gap-1 items-center sm:mr-20'>
              <div className="flex-shrink-0">
                <GrLocation size={20} className='text-[#F2503A]' />
              </div>
              <p className="text-[14px] text-gray-600 break-normal break-words overflow-hidden line-clamp-1">
                {locationName || "Location not available"}
              </p>
            </div>
          </div>

          <div className='flex flex-col gap-0 sm:gap-1'>
            <h3 className="text-[16px] sm:text-xl font-semibold leading-0 break-normal break-words overflow-hidden line-clamp-1">
              {title ? title : "Title not available"}
            </h3>
            {/** DESCRIPTION OR genre/targetaudience */}
            <div className='text-[14px] sm:text-base text-gray-500 overflow-hidden text-ellipsis line-clamp-2'>
              <p className='tracking-tight text-[14px] break-normal break-words overflow-hidden line-clamp-2'>
                {displayGenre(genre, l)}, {displayTargetAudience(targetAudience, l)}
              </p>
            </div>
          </div>
        </div>

        {/** ORGANIZER */}
        <div className="flex items-center text-base font-bold gap-1 mt-auto">
          <ProfilePictureIcon 
                height='h-[32px] md:h-[32px]'
                className='cursor-pointer hover:shadow-md'
                image={instructor.profilePicture}
            />
          <p className='text-[16px]'>
            {instructor.instructorName}
          </p>
        </div>
      </div>

      <div className='ml-auto mr-3 py-3 h-full flex flex-col justify-between items-end'>
        <div 
          onClick={onBookmarkClick}
          className='p-1 h-fit rounded-full cursor-pointer hover:bg-white hover:shadow-lg
        '>{ isFollowing ? (
            <RiHeartFill size={20} className='text-pink-500 '/>
          ) : (
            <RiHeartLine size={20} className='text-gray-500 '/>
          )}
        </div>
        <p className='text-lg font-medium'>¥{lessonData.base_price ? lessonData.base_price : 0}</p>
      </div>

      
    </div>
  )
}