import { Middleware } from "@reduxjs/toolkit";

import { useEffect } from "react";
import { useRouter } from "next/router";

//import { useAppDispatch } from "@/src/hooks/useDispatch";
import { getBookmarks } from "./slices/bookmarkSlice";
import { userLogout } from "./slices/userSlice";
// import { getUserSettings } from "./slices/settingsSlice";
//import store from "./store";

// Custom middleware
export const updateCartMiddleware: Middleware<{}, any, any> = (store) => (next) => (action) => {
    


    
    // Get the current state
    const currentState = store.getState();
    // Dispatch the action
    const result = next(action);
    // Get the updated state
    const nextState = store.getState();

    if (currentState.user.data !== nextState.user.data) {
        // check if token exists in user
        if (nextState.user && nextState.user.data && nextState.user.data.user) {
            const {token} = nextState.user.data.user
            if (token) {
                // Your code to run whenever the "user" state changes
                //console.log('User state changed:', nextState.user);
                //console.log("this is token:", token)
                //console.log("Calling getBookmarks")
                store.dispatch(getBookmarks())
                // store.dispatch(getUserSettings())
            }
        } else {
            store.dispatch(userLogout())
        }
    }





    // Continue with the action
    return result;
  };