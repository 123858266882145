import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from "next/image";
import axios from 'axios'
import * as Sentry from "@sentry/nextjs";



//atoms
import { Alert } from '@/src/atoms/alert';
import { Button } from '@/src/atoms-new/Button';

//lesson cards
import { PublicLessonCard } from '@/src/components-new/card/PublicLessonCard';
//components
import { UserLayout } from '@/src/components/layout/user/UserLayout'
import { HomepageSearchBar } from '@/src/components-etc/homepage-search-bar/HomepageSearchBar';
import { SubscribePlaceholder } from '@/src/components-etc/subscribe-placeholder/SubscribePlaceholder';

//redux
import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { addBookmarkLesson, removeBookmarkLesson } from '@/store/slices/bookmarkSlice';

import { PublicLessonCardType } from '@/src/types/public/lesson';

//type
import { AlertType } from '@/src/atoms/alert';



export async function getServerSideProps() {
    // Access the environment variable
    const backend_url = process.env.BACKEND_URL;
    return { props: { backend_url }, };
}
  
interface HomeProps {
    backend_url: string;
}

export default function Home({ backend_url }:HomeProps) {
    const router = useRouter();
    const dispatch = useAppDispatch()

    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    /** ALERT BANNER */
    const [alertType, setAlertType] = useState<AlertType>('success')
    const [alertText, setAlertText] = useState('')
    
    /** AXIOS: GET MOST RECENT DANCE CLASSES */
    const [lessons, setLessons] = useState<PublicLessonCardType[]>([])
    useEffect(() => {
        axios.get( backend_url + '/api/lesson/search-lessons/' )
            .then(response => {
                if (response.data.length) {
                    const formattedData = response.data.map((d:any) => {
                        const formattedLesson = {...d}
                        if (formattedLesson.lessonClass) {
                            const formattedClass = {...formattedLesson.lessonClass}
                            formattedClass.start_datetime = new Date(formattedClass.start_datetime)
                            formattedClass.end_datetime = new Date(formattedClass.end_datetime)
                            formattedLesson.lessonClass = formattedClass;
                        }
                        return formattedLesson
                    })
                    setLessons(formattedData)
                } else {
                    setLessons([])
                }
            })
            .catch(error => {
                console.log("error getting all lesson classes")
                console.log(error)
            })
    },[backend_url])
    const lessonClickHandler = (classId: string) => {
        router.push(`/lesson-profile/${classId}`)
    }


    /** BOOKMARK */
    const bookmarkData = useAppSelector(state => state.bookmark).data
    // console.log(bookmarkLessons)
    const bookmarkLessonHandler = (lessonId:string) => {
        if (bookmarkData && bookmarkData.lesson) {
            //check if in boomkarkllist.
            if (bookmarkData.lesson.includes(lessonId)) {
                // remove from list
                dispatch(removeBookmarkLesson(lessonId))
            } else {
                // add to list
                dispatch(addBookmarkLesson(lessonId))
            }
        } else {
            //route to login
        }
    }


    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        search_dance_lessons:{
            en:'Search Dance Lessons',
            jp:'ダンスレッスンを検索'
        },
        learn_genres:{
            en:'Learn Various Genres',
            jp:'ジャンルは色々'
        },
        recent_lessons: {
            en: 'Recently Posted Dance Lessons',
            jp:'最新のレッスン'
        },
        no_lessons_text: {
            en:'There are currently no classes on Ambition',
            jp:'現在ダンスのレッスンはありません。'
        },
        submit_email_text: {
            en:'Submit your email below to be notified when there are new lessons',
            jp:'新しいレッスンが追加された際に通知を受け取るには、以下にメールアドレスを入力してください。'
        },
    }


    return (
        <UserLayout >
            <div className='flex flex-col relative'>
                <Alert 
                    isVisible={alertText ? true : false}
                    hideAlert={() => setAlertText('')}
                    autoClose={true}
                    type={alertType}
                    message={alertText}
                    className={alertText ? 'sticky top-0' : 'hidden'}
                    zIndex={50}
                />
                
                {/** HERO SECTION */}
                <div className='flex flex-col items-center h-[85vh] md:h-[85vh] text-center w-full bg-white xl:justify-around xl:flex-row'>
                
                    <div className='
                        flex flex-col gap-20 w-full h-full sm:h-5/6 items-center py-[100px] justify-center 
                        xl:justify-start 
                    '>
                        <div className='flex flex-col w-full xl:pl-24 items-center xl:items-start'>
                            <h1 className='text-black text-[30px] sm:text-[40px]'>What is your <p className='hidden'>Ambition</p></h1>
                            <br/>
                            <div className='relative w-fit'>
                                <p 
                                    className='text-[50px] sm:text-[70px] md:text-[100px] font-bold leading-none text-prime-orange-normal absolute -top-1 -left-1 tracking-widest'
                                    style={{ fontFamily: 'G-Knewave' }}
                                    >AMBITION
                                </p>
                                <p 
                                    className='text-[50px] sm:text-[70px] md:text-[100px] font-bold leading-none text-black tracking-widest '
                                    style={{ fontFamily: 'G-Knewave' }}
                                    >AMBITION
                                </p>
                            </div>
                        </div>

                        {/** CUSTOM SELECT */}
                        <div className='w-full max-w-[400px] sm:max-w-[600px] md:max-w-full px-10 md:px-4 lg:px-16 xl:px-10  sm:px-24'>
                            {/* <HomepageSearchBar 
                                l={l}
                            /> */}
                            <div className='w-full flex justify-center xl:justify-start xl:pl-10'>
                                <Button
                                    color='bg-blue-600 hover:bg-blue-700'
                                    textColor='text-white'
                                    borderColor=''
                                    className='w-full md:w-1/2'
                                    onClick={() => router.push('/search-lessons')}
                                    >{text['search_dance_lessons'][l]}
                                </Button>
                            </div>
                        </div>
                    </div>
                    
                    <div className='w-[60%] h-full flex items-center justify-center bg-white overflow-hidden hidden xl:flex'>
                            
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <Image
                                src="/images/production/homepage-photo-breaking.jpeg"
                                alt="Breaking" 
                                style={{ objectFit: 'cover'}}
                                fill
                                sizes="(max-width: 768px) 100vw, 
                                    (max-width: 1200px) 50vw, 
                                    33vw"
                                priority
                            />
                        </div>
                    </div>
                </div>

                {/** GENRE DISPLAY SECTION */}
                <div className='flex flex-col items-center gap-10 xl:gap-28 min-h-[85vh] bg-gray-100 p-10 w-screen'>
                    <h2 className=''>{text['learn_genres'][l]}</h2>

                    {/** GENRE SHOWCASE CARDS */}
                    <div className='flex flex-wrap justify-around gap-y-10 gap-x-4'>
                        {/** BREAKING */}
                        <div className='w-[250px] h-[350px] overflow-hidden relative'>
                            <Image
                                src="/images/production/breakdance.jpg"
                                alt="Breaking" 
                                style={{ objectFit: 'cover' }}
                                fill
                                sizes="(max-width: 768px) 100vw, 
                                    (max-width: 1200px) 50vw, 
                                    33vw"
                            />
                            <p className='text-white font-semibold tracking-wider text-[20px] absolute bottom-3 left-3'>Breaking</p>
                        </div>
                        {/** POPPING */}
                        <div className='w-[250px] h-[350px] overflow-hidden relative'>
                            <Image
                                src="/images/production/popping.jpeg"
                                alt="Popping" 
                                style={{ objectFit: 'cover' }}
                                fill
                                sizes="(max-width: 768px) 100vw, 
                                    (max-width: 1200px) 50vw, 
                                    33vw"
                            />
                            <p 
                                className='text-white shadow-md font-semibold tracking-wider text-[20px] absolute bottom-3 left-3'
                                >Popping
                            </p>
                        </div>
                        {/** HIP HOP */}
                        <div className='w-[250px] h-[350px] overflow-hidden relative'>
                            <Image
                                src="/images/production/hip-hop.jpeg"
                                alt="Hip Hop" 
                                style={{ objectFit: 'cover' }}
                                fill
                                sizes="(max-width: 768px) 100vw, 
                                    (max-width: 1200px) 50vw, 
                                    33vw"
                            />
                            <p 
                                className='text-white shadow-md font-semibold tracking-wider text-[20px] absolute bottom-3 left-3'
                                >Hip Hop
                            </p>
                        </div>
                        {/** KRUMP */}
                        <div className='w-[250px] h-[350px] overflow-hidden relative'>
                            <Image
                                src="/images/production/krump.jpeg"
                                alt="Krump" 
                                style={{ objectFit: 'cover' }}
                                fill
                                sizes="(max-width: 768px) 100vw, 
                                    (max-width: 1200px) 50vw, 
                                    33vw"
                            />
                            <p 
                                className='text-white shadow-md font-semibold tracking-wider text-[20px] absolute bottom-3 left-3'
                                >Krump
                            </p>
                        </div>
                    </div>
                </div>

                {/** Recent Lessons SECTIONS */}
                <div className='flex flex-col gap-8 items-center min-h-[85vh] p-10'>
                    <h2 className=''>{text['recent_lessons'][l]}</h2>

                    {   lessons.length ? (
                        <div className='flex flex-col gap-3 w-full lg:w-2/3 xl:w-1/2 items-center'>
                            {   lessons.length>0 && lessons.map((lesson:any) => {
                                return (
                                    <PublicLessonCard 
                                        key={lesson.lessonId}
                                        l={l}
                                        lessonData={lesson}
                                        bookmarkHandler = {() => bookmarkLessonHandler(lesson.lessonId)}
                                        onClick={() => lessonClickHandler(lesson.lessonClass.classId)}
                                        isFollowing={bookmarkData && bookmarkData.lesson && bookmarkData.lesson.includes(lesson.lessonId) ? true : false}
                                    />
                                )
                            })}
                        </div>
                    ) : (
                        <SubscribePlaceholder 
                            alreadySubscribedHandler={() => {
                                setAlertType('error');
                                setAlertText('Email already subscribed')
                            }}
                            successHandler={() => {
                                setAlertType('success')
                                setAlertText('Email subscribed')
                            }}
                            userExistsHandler={() => {
                                setAlertType('error')
                                setAlertText('User exists with email')
                            }}
                        >
                            <div className='flex flex-col gap-4 items-center'>
                                <p>{text['no_lessons_text'][l]}</p>
                                <p>{text['submit_email_text'][l]}</p>
                            </div>

                        </SubscribePlaceholder>
                    )}
                </div>
            </div>
        </UserLayout>
    )
}

