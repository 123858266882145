import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import axios from 'axios';
import { fileToBase64 } from '@/src/helpers/image';

export const createAccount = createAsyncThunk(
  'user/createAccount',
  async( formData:{}, {}) => {
    //axios call to create user
    const config = { headers: {
      'Content-Type': 'Application/json',
    }}  

    //get env var
    const res = await fetch('/api/env/backend-url')
    const {backendUrl} = await res.json();
    if (!backendUrl) {
      console.error('Backend URL env is missing');
      return 
    }

    const response = await axios.post(
      `${backendUrl}/api/user/create-account/`,
      formData,
      config
    )
    return response.data
  }
)

export const createGoogleAccount = createAsyncThunk(
  'user/createGoogleAccount',
  async( formData:{}, {}) => {
    //axios call to create user
    const config = { headers: {
      'Content-Type': 'Application/json',
    }}  

    //get env var
    const res = await fetch('/api/env/backend-url')
    const {backendUrl} = await res.json();
    if (!backendUrl) {
      console.error('Backend URL env is missing');
      return 
    }

    const response = await axios.post(
      `${backendUrl}/api/user/google-login-signup/`,
      formData,
      config
    )
    return response.data
  }
)

//sign-up/verify-email
export const verifyEmail = createAsyncThunk(
  'user/verifyEmail',
  async(formData:{uid:string, token:string},{getState}) => {
    const userState:any = getState();
    const userData = userState.user.data;

    //form data
    const uid = formData['uid']
    const token = formData['token']
    
    const config = { headers: {
      'Content-Type': 'Application/json',
    }}  

    //get env var
    const res = await fetch('/api/env/backend-url')
    const {backendUrl} = await res.json();
    if (!backendUrl) {
      console.error('Backend URL env is missing');
      return userState.user.data
    }

    // send axios request
    const response = await axios.post(
      `${backendUrl}/api/user/verify-email/${uid}/${token}`,
      config
    )
    return response.data
  }
)


export const updateNotificationLastChecked = createAsyncThunk(
  'user/updateNotificationLastChecked',
  async (_, {getState}) => {
    //get state to access token and current notif last checked
    const userState:any = getState();
    const {user} = userState.user.data;

    let token;
    if ('token' in user) {  token = user.token } 
    //check for token in current state first
    if (token) {
      const {notification_last_checked} = user
      const currentDate = new Date();
      if (notification_last_checked) {
        const lastNotification = new Date(notification_last_checked)
        // update notification
        if (lastNotification < currentDate) {
          //create config header object
          const config = {
            headers: {
              'Content-Type': 'Application/json',
              Authorization: `Bearer ${token}`
            }
          }

          //get env var
          const res = await fetch('/api/env/backend-url')
          const {backendUrl} = await res.json();
          if (!backendUrl) {
            console.error('Backend URL env is missing');
            return userState.user.data
          }

          //make patch to update read
          const response = await axios.post(
            `${backendUrl}/api/user/update-user/`,
            {notification_last_checked: currentDate},
            config
          )
          return response.data
        }
      }
    }
    return userState.user.data
  }
)




export interface UserStateProps {
  data: any;
  status: string;
  error: string | null;
}

const initialData:any = {
  user: {}, basic_profile:{}, 
  // user_profile: {}
}

const initialState: UserStateProps = {
  data: initialData,
  status: 'idle',
  error:'null'
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    userLogin: (state, action) => {
      state.data = action.payload
    },
    changeEmail: (state, action) => {
      state.data = action.payload
    },
    userLogout: (state) => {
      state.data = initialData
    },
    setUserData: (state, action) => {
      state.data = action.payload
    },

    updateUser: (state, action) => {
      state.data = {...state.data, user: {...state.data.user, ...action.payload}}
    },
    updateBasicProfile: (state, action) => {
      state.data = {...state.data, basic_profile: action.payload}
    },
    // updateUserProfile: (state, action) => {
    //   state.data = {...state.data, user_profile: action.payload}
    // },
    updateUserProfilePicture: (state, action) => {
      state.data = {...state.data, user:{...state.data.user,profilePicture:action.payload }}
    }



  },
  extraReducers: (builder) => {
    //createAccount
    builder.addCase(createAccount.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createAccount.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(createAccount.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })
    //createGoogleAccount
    builder.addCase(createGoogleAccount.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createGoogleAccount.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(createGoogleAccount.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })
    //verifyEmail
    builder.addCase(verifyEmail.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(verifyEmail.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    builder.addCase(updateNotificationLastChecked.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateNotificationLastChecked.fulfilled, (state, action) => {
      state.status = 'succeeded'
      // state.data = action.payload
      state.data = {...state.data, user: action.payload}
    })
    builder.addCase(updateNotificationLastChecked.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

  },
});

//reducers
export const {userLogin, changeEmail, userLogout, setUserData, updateUser, updateBasicProfile, 
  // updateUserProfile, 
  updateUserProfilePicture} = userSlice.actions;

//export const selectUser = (state) = state.user
export default userSlice.reducer;
